import React, { Component } from "react";
import ParticlesBg from "particles-bg";
import Fade from "react-reveal";

class Header extends Component {
  render() {
    if (!this.props.data) return null;

    const project = this.props.data.project;
    const github = this.props.data.github;

    return (
      <header id="home">
        <ParticlesBg type="circle" bg={true} />

        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li className="current">
              <a className="smoothscroll" href="#home">
                Home
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#about">
                About
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#service">
                Our Services
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#contact">
                Contact
              </a>
            </li>

            <li>
              <a  href="/user/login">
                Try it
              </a>
            </li>
          </ul>
        </nav>

        <div className="row banner">
          <div className="banner-text">
            <Fade bottom>
              <h1 className="responsive-headline" style={{marginBottom: 0}}>PROPHET</h1>
              <h2 className="responsive-headline" style={{color: 'white', paddingBottom: 15, paddingTop: 0}}>Read your energies</h2>
			  <p  style={{color: 'white', fontSize: 25}}> ProPhet is a system for energy management (EMS). It is scalable, flexible and intergrated Artificial Inteligence
				for predicting the future usage and detect abnormablies on historical energy usage. </p>
            </Fade>
            <Fade bottom duration={1200}>
              <th>
                <div class="problem-section">
                  <div class="image-wrapper">
                    <img
                      src="/images/header/analitical.png"
                      alt="Energy-efficient buildings"
					  style={{borderRadius: 20}}
                    ></img>
                  </div>
                  <div class="problem-description">
                    <div class="col">
                      <h3  style={{color: 'white', fontSize: 20, paddingBottom: -10, paddingTop: 0}} >Energy management systems and the current state </h3>
                      <p style={{color: 'white', fontSize: 18, fontWeight: "normal"}}>
						The purpose of a energy management system is helping operators 
						monitor, control, and optimize the performance of an electric 
						utility grid or small-scale system. However the classical energy management 
						systems are often inadequate in optimizing energy 
						consumption. They lack of real-time data analysis, 
						predictive capabilities, and efficient control mechanisms.
                      </p>
                    </div>
                  </div>
                </div>
              </th>
            </Fade>
            <hr />
            <Fade bottom duration={1200}>
              <th>
                <div class="problem-section">
                  <div class="image-wrapper">
                    <img
                      src="/images/header/introduction.png"
                      alt="Energy-efficient buildings"
                    ></img>
                  </div>
                  <div class="problem-description">
                    <div class="col">
                      <h3  style={{color: 'white', fontSize: 20, paddingBottom: -10, paddingTop: 0}} >Our new EMS </h3>
					  <li style={{color: 'white', fontSize: 18, fontWeight: "normal"}}>
						Energy usage forcasting: The AI engine is capable of forcast 4 day energy usage with 71% accuracy.
                      </li>
					  <li style={{color: 'white', fontSize: 18, fontWeight: "normal"}}>
						Flexibility: The devices can be easily injected to existed power system without require much effort to install. 
                      </li>
					  <li style={{color: 'white', fontSize: 18, fontWeight: "normal"}}>
						Scalability: The AI forcast engine is automaticly fit with the recent usage and the scale of the power system. The AI engine design for general power system, it have no specific system, no need to re-train the AI model manually.
                      </li>
					  <li style={{color: 'white', fontSize: 18, fontWeight: "normal"}}>
						Real-time data collecting and publishing: Our system colected data on multiple device and publish it for intergrating purposes over the Internet.
                      </li>
                    </div>
                  </div>
                </div>
              </th>
            </Fade>
            {/* <Fade bottom duration={2000}>
              <ul className="social">
                <a href={project} className="button btn project-btn">
                  <i className="fa fa-book"></i>Project
                </a>
                <a href={github} className="button btn github-btn">
                  <i className="fa fa-github"></i>Github
                </a>
              </ul>
            </Fade> */}
          </div>
        </div>

        <p className="scrolldown">
          <a className="smoothscroll" href="#about">
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </header>
    );
  }
}

export default Header;
