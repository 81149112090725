import React, { Component } from "react";
import Fade from "react-reveal";


class Footer extends Component {
  render() {
    if (!this.props.data) return null;

    const networks = this.props.data.social.map(function (network) {
      return (
        <li key={network.name}>
          <a href={network.url}>
            <i className={network.className}></i>
          </a>
        </li>
      );
    });
   const logos = this.props.data.logo.map(function(logoo){
    let projectImage = "images/logo/" + logoo.img;
    return (
      <li key={logoo.name} >
        <img className="logo" alt={logoo.name} src={projectImage} />
      </li>
    )
   })

    return (
      <footer>
        <div className="row">
          <Fade bottom>
            <div className="twelve columns">
              {/* <ul className="social-links">{networks}</ul> */}
              <ul className="infor_footer">
                <ul>
                  <h5 className="infor_footer">Zenith Team</h5>
                  <li>About us</li>
                  <li>Contact us</li>
                </ul>
                <ul>
                  <h5 className="infor_footer">Sponsors</h5>
                  <li> Rockwell Automation</li>
                  <li> First Solar</li>
                  <li> Dow</li>
                  <li> Arizona State University</li>
                  <li> Industrial University of HCMC </li>
                </ul>
              </ul>
              <ul className="logo_u_footer">
                {logos}
              </ul>
              
            </div>
          </Fade>

          <div id="go-top">
            <a className="smoothscroll" title="Back to Top" href="#home">
              <i className="icon-up-open"></i>
            </a>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
