import React, { Component } from "react";
import ReactGA from "react-ga";
import $ from "jquery";
import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import About from "./Components/About";
import Service from "./Components/Service";
import Contact from "./Components/Contact";
import Portfolio from "./Components/Portfolio";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foo: "bar",
      serviceData: {}
    };

    ReactGA.initialize("UA-110570651-1");
    ReactGA.pageview(window.location.pathname);
  }

  getserviceData() {
    $.ajax({
      url: "./serviceData.json",
      dataType: "json",
      cache: false,
      success: function(data) {
        this.setState({ serviceData: data });
      }.bind(this),
      error: function(xhr, status, err) {
        console.log(err);
        alert(err);
      }
    });
  }

  componentDidMount() {
    this.getserviceData();
  }

  render() {
    return (
      <div className="App">
        <Header data={this.state.serviceData.main} />
        <About data={this.state.serviceData.main} />
        <Service data={this.state.serviceData.service} />
        <Portfolio data={this.state.serviceData.portfolio} />
        <Contact data={this.state.serviceData.main} />
        <Footer data={this.state.serviceData.main} />
      </div>
    );
  }
}

export default App;
