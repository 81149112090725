import React, { Component } from "react";
import Fade from "react-reveal";

class About extends Component {
  render() {
    if (!this.props.data) return null;

    return (
      <section id="about">
        <Fade duration={1000}>
          <div className="block-left">
              <div class="row-info">
                <div class="person-info">
                  <img className="profile-pic" src="/images/avatar/nhung_pic.png" alt="person image1"/>
                  <h2>Nguyễn Phú Điền Nhung</h2>
                  <p>Leader</p>
                </div>
                <div class="person-info">
                  <img className="profile-pic" src="/images/avatar/thien_pic.jpg" alt="person image2"/>
                  <h2>Lê Chí Thiện</h2>
                  <p>Electronics Technician</p>
                </div>
                <div class="person-info">
                  <img className="profile-pic" src="/images/avatar/truc_pic.jpg" alt="person image3"/>
                  <h2>Đỗ Thanh Trúc</h2>
                  <p>AI Engineer</p>
                </div>
                <div class="person-info">
                  <img className="profile-pic" src="/images/avatar/thuan_pic.png" alt="person image4"/>
                  <h2>Nguyễn Lê Duy Thuận</h2>
                  <p>Web Server Seveloper</p>
                </div>
              </div>
              <div class="row-info">
                <div class="person-info">
                  <img className="profile-pic" src="/images/avatar/hieu_pic.jpg" alt="person image5"/>
                  <h2>Nguyễn Anh Hiếu</h2>
                  <p>Enclosure Designer</p>
                </div>
                <div class="person-info">
                  <img className="profile-pic" src="/images/avatar/anh_pic.jpg" alt="person image6"/>
                  <h2>Lê Quang Anh</h2>
                  <p>FactoryTalk Optix UI Designer</p>
                </div>
                <div class="person-info">
                  <img className="profile-pic" src="/images/avatar/thu_pic.jpg" alt="person image7"/>
                  <h2>Trần Thị Minh Thư</h2>
                  <p>Secretary</p>
                </div>
              </div>
            </div>
          <div className="block-right main-col">
              <h2>About Zenith</h2>
              <p>We are a group of electronics enthusiast at Industrial University of Ho Chi Minh City.</p>
              <p>Sponsored by the eProject5 Program, we created this system for energy monitoring system. 
				Initially, we created the system for uses with light control system as a ad hoc case.</p>
          </div>
        </Fade>
      </section>
    );
  }
}

export default About;
